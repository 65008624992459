<script>
  import { onMount, onDestroy } from "svelte";
  let rsvpInfo = [];
  let error = "";
  $: noRsvpResponses = rsvpInfo.filter(x => !x.rsvped);
  $: yesResponses = rsvpInfo.filter(x => x.rsvped && x.coming);
  $: noResponses = rsvpInfo.filter(x => x.rsvped && !x.coming);
  const API_URL = "https://saintilway.com/rsvp-api";

  async function fetchJSON(url, opts = {}) {
    return fetch(url, { ...opts }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    });
  }


 function getSize(list) {
   let total = 0;
   list.forEach(x => total += x.count);
   return total;
 }

 onMount(() => {
   const rsvpKey = new URLSearchParams(window.location.search).get('rsvp-key');

   if (rsvpKey) {
     fetchJSON(`${API_URL}/responses?rsvp-key=${rsvpKey}`)
       .then(response => {
         rsvpInfo = response.rsvpInfo;
       })
       .catch(e => {
         error = "Invalid RSVP key";
       });
   }
 });
</script>

<div class="holder">
  <h3>Missing RSVP ({getSize(noRsvpResponses)})</h3>
  {#each noRsvpResponses as invitee}
    <li>{invitee.name} ({invitee.count})</li>
  {/each}
  <h3>Responded Yes ({getSize(yesResponses)})</h3>
  {#each yesResponses as invitee}
    <li>{invitee.name} ({invitee.count})</li>
  {/each}
  <h3>Responded No ({getSize(noResponses)})</h3>
  {#each noResponses as invitee}
    <li>{invitee.name} ({invitee.count})</li>
  {/each}
</div>
