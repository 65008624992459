<script>
  import { rsvped } from "./store.js";
  export let active;

  $: isActive = str => (active === str ? "selected" : "");
  const RSVP_CLOSED_DATE = "2021-09-21T20:30:00.000Z";

  function isRSVPClosed(now) {
    return now > new Date(RSVP_CLOSED_DATE).getTime() ? "rsvp-closed" : "";
  }
</script>

<style>
  nav {
    font-weight: 100;
    padding: 2em 1em 0 0;
    width: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .selected {
    position: relative;
    display: inline-block;
    font-weight: 400;
    color: #1e2f3d;
  }
  .selected::after {
    position: absolute;
    content: "";
    width: calc(100% - 24px);
    height: 2px;
    background-color: #1e2f3d;
    display: block;
    bottom: -1px;
  }
  a {
    text-decoration: none;
    padding: 0 24px 2px 0;
    display: block;
    color: black;
  }

  #rsvp-closed {
    pointer-events: none;
    cursor: default;
  }
</style>

<nav>
  <ul>
    <li>
      <a class={isActive('home')} href="/">Home</a>
    </li>
    <li>
      <a class={isActive('#resources')} href="/#resources">Resources</a>
    </li>
    <li>
      <a
        id={isRSVPClosed(Date.now())}
        class={isActive('#rsvp')}
        href="/#rsvp"
        disabled>
        {#if $rsvped === '1'}
          RSPVed
          <span>&#10003;</span>
        {:else if $rsvped === '2'}
          RSPVed
          <span>&#10007;</span>
        {:else}RSVP{/if}
      </a>
    </li>
    <!-- <li>
      <a class={isActive('#registries')} href="/#registries">Registries</a>
    </li> -->
  </ul>
</nav>
