<script>
  let timeleft = "";
  let event = Date.parse("2021-10-03T20:00:00.000Z") - Date.now();
  import { onMount, onDestroy } from "svelte";

  // October 3, 2021 (4 EST)
  const eventDate = Date.parse("2021-10-03T20:30:00.000Z");
  // const eventDate = Date.now() + 5 * 1000;
  const DAY = 1000 * 60 * 60 * 24;
  const HOUR = DAY / 24;
  const MINUTE = HOUR / 60;
  const SECOND = MINUTE / 60;

  let interval = setInterval(updateTime, 1000);

  function updateTime() {
    const millescondsLeft = eventDate - Date.now();
    let unitLeft;
    let unit;

    if (millescondsLeft > DAY) {
      unitLeft = millescondsLeft / DAY;
      unit = "day";
    } else if (millescondsLeft > HOUR) {
      unitLeft = millescondsLeft / HOUR;
      unit = "hour";
    } else if (millescondsLeft > MINUTE) {
      unitLeft = millescondsLeft / MINUTE;
      unit = "minute";
    } else if (millescondsLeft > SECOND) {
      unitLeft = millescondsLeft / SECOND;
      unit = "second";
    }

    if (-millescondsLeft > 8 * HOUR) {
      timeleft = "Thank you for attending!";
      return;
    }

    if (-millescondsLeft > 30 * MINUTE) {
      timeleft = "Event has started";
      return;
    }

    unitLeft = Math.floor(unitLeft);
    if (!unitLeft && !unit) {
      timeleft = "Event is starting...";
      return;
    }

    timeleft = `${unitLeft} ${unit}${
      unitLeft === 1 ? "" : "s"
    } left until event`;
  }

  onMount(() => {
    updateTime();
  });

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<style>
  span {
    display: block;
  }

  .name {
    font-size: 3rem;
  }

  .info {
    margin-top: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .image {
    width: 500px;
    background: grey;
  }

  .date {
    font-size: 32px;
    margin-top: 10vh;
  }
  .time-info {
    font-size: 18px;
    color: #818181;
  }
  @media only screen and (max-width: 1024px) {
    .info {
      margin-top: 10vh;
      flex-direction: column;
    }

    .image {
      width: 90%;
      margin-top: 48px;
    }

    .small-screen-center {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .name {
      font-size: 2.5rem;
    }
  }
</style>

<div class="info">
  <div class="small-screen-center">
    <span class="name">Dorothy Chapont</span>
    <span class="name">Wendly Saintil</span>
  </div>
  <img
    class="image"
    src="https://cdnw.nyc3.digitaloceanspaces.com/PXL_20201003_194318035.PORTRAIT.jpg"
    alt="Picture of Wendly and Yvelyne" />
</div>

<div class="small-screen-center">
  <span class="date">October 3, 2021</span>
  <span class="time-info">{timeleft}</span>
</div>
