<script>
  import { rsvped } from "./store.js";
  let hasRsvpCode = false;
  let responseNo = false;
  let responseYes = false;
  let responseSubmitted = false;
  let responderName = "";
  let newName = "";
  let members = [];
  let count = 0;
  let rsvpCode = "";
  const API_URL = "https://saintilway.com/rsvp-api";
  let error = "";

  async function fetchJSON(url, opts = {}) {
    return fetch(url, { ...opts }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    });
  }

  function rsvpYesClick(e) {
    error = "";
    fetchJSON(`${API_URL}/${rsvpCode}`)
      .then(info => {
        responderName = info.name;
        count = info.count;
        members = info.members;
        hasRsvpCode = true;
        responseYes = true;
      })
      .catch(e => {
        error = "No reservation found.";
      });
  }

  function rsvpNoClick(e) {
    error = "";
    fetchJSON(`${API_URL}/${rsvpCode}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ coming: false })
    })
      .then(info => {
        responderName = info.name;
        hasRsvpCode = true;
        responseNo = true;
        rsvped.set("2");
      })
      .catch(e => {
        error = "No reservation found.";
      });
  }

  function handleRemove(member) {
    members = members.filter(x => x !== member);
  }

  function handleSubmit(e) {
    error = "";
    fetchJSON(`${API_URL}/${rsvpCode}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ coming: true, members })
    })
      .then(info => {
        responderName = info.name;
        responseSubmitted = true;
        rsvped.set("1");
      })
      .catch(e => {
        error = "No reservation found.";
      });
  }

  function submitNewName(e) {
    members = [...members, newName];
  }
</script>

<style>
  .center {
    display: flex;
    flex-direction: column;
    margin-top: 25vh;
  }
  .flex {
    display: flex;
  }
  input {
    border-width: 0 0 2px 0;
    width: 480px;
  }
  input.rsvp {
    font-size: 24px;
  }
  input.new-name {
    width: 300px;
    margin-right: 24px;
  }
  button {
    cursor: pointer;
    border: none;
    background: #1e2f3d;
    color: #ffffff;
  }
  button.secondary {
    background: none;
    color: #000000;
  }
  button.small {
    font-size: 10px;
  }
  .buttons {
    margin-top: 24px;
  }
  .rsvp-header {
    font-size: 20px;
  }
  .pad-top {
    margin-top: 24px;
  }
  .error {
    color: rgb(194, 0, 0);
  }

  @media only screen and (max-width: 1024px) {
    input,
    input.new-name {
      width: 75vw;
      max-width: 360px;
    }
  }
</style>

<div class="center">
  {#if !hasRsvpCode}
    <p>
      Kindly respond by
      <strong>Sept 20th, 2021</strong>
    </p>
    <input
      class="rsvp"
      placeholder="rsvp-done"
      type="text"
      bind:value={rsvpCode} />

    {#if rsvpCode.length > 0}
      <div class="buttons">
        <button on:click={rsvpYesClick}>Yes, I Plan to attend</button>
        <button class="secondary" on:click={rsvpNoClick}>
          <strong>No, I cannot make it</strong>
        </button>
      </div>
    {/if}
  {:else if responseSubmitted}
    <p class="rsvp-header">
      <strong>Thanks</strong>
      for your response, {responderName}
    </p>
    <span>We’re so happy you’re joining us :-)</span>
  {:else if responseYes}
    <p class="rsvp-header">
      <strong>Yay!</strong>
      We’re so happy that you’re joining us
    </p>
    <p>
      This is the list of name{members.length === 1 ? '' : 's'} that we found in
      your reservation
    </p>
    {#each members as member}
      <div>
        <span>{member}</span>
        <button class="small" on:click={() => handleRemove(member)}>
          Remove
        </button>
      </div>
    {/each}
    {#if count > members.length}
      <div class="flex">
        <input class="new-name" placeholder="new-name" bind:value={newName} />
        <button on:click={submitNewName}>Add new name</button>
      </div>
    {/if}
    <div>
      <button class="pad-top" on:click={handleSubmit}>
        Submit {members.length} name{members.length === 1 ? '' : 's'}
      </button>
    </div>
  {:else if responseNo}
    <p class="rsvp-header">
      <strong>Thanks</strong>
      for your response, {responderName}
    </p>
    <span>We’ll miss you :-(</span>
  {/if}
  <strong class="error">{error}</strong>
</div>
