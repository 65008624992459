<script lang="ts">
  import Navaid from "navaid";
  import { onDestroy } from "svelte";
  import Nav from "./Nav.svelte";
  import Home from "./Home.svelte";
  import Rsvp from "./Rsvp.svelte";
  import Resources from "./Resources.svelte";
  import Registries from "./Registries.svelte";
  import Responses from "./Responses.svelte";
  import { onMount } from "svelte";
  export let name = "";
  let Route,
    params = {},
    active;

  let uri = window.location.hash;
  $: active = uri.split("/")[1] || uri || "home";

  function run(route: any) {
    Route = route;
    window.scrollTo(0, 0);
  }

  function track(obj: any) {
    uri = obj.state || obj.uri || window.location.hash;
    // if (window.ga) ga.send("pageview", { dp: uri });
  }
  addEventListener("replacestate", track);
  addEventListener("pushstate", track);
  addEventListener("popstate", track);

  const router = Navaid("/")
    .on("/", () => {
      const module: { [key: string]: any } = {
        "#resources": Resources,
        "#rsvp": Rsvp,
        "#registries": Registries,
        "#responses": Responses,
      };
      run(module[window.location.hash] || Home);
    })
    .listen();

  onDestroy(router.unlisten);
</script>

<style>
  h1 {
    color: purple;
  }
  .container {
    max-width: 960px;
    margin: auto;
  }

  @media only screen and (max-width: 1024px) {
    .container {
      max-width: 80vw;
    }
  }
</style>

<div class="container">
  <Nav {active} />
  <main>
    <svelte:component this={Route} {params} />
  </main>
</div>
